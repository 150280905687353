import { Select, SelectProps } from '@shared/Form/Select'
import { useAllSessions } from '@services/session'
import { useTranslation } from 'react-i18next'

type Props = SelectProps & {
  filterCondition?: any
}

export default function SessionSelect({ ...props }: Props) {
  const { data } = useAllSessions()

  const { t } = useTranslation()

  return (
    <Select allowClear placeholder={t('device:search sessionName')} {...props}>
      {data
        .filter(x => x.available)
        .filter(x => x.status === 1)
        .filter(x => (props?.filterCondition ? props.filterCondition?.(x) : x))
        .map(x => (
          <Select.Option key={x.id} value={x.id}>
            {x.name}
          </Select.Option>
        ))}
    </Select>
  )
}
